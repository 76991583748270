<template>
  <div class="text-left">
    <div
      v-for="(item, index) in rows"
      :key="item.id + index"
      class="list item d-flex justify-content-between align-items-center pbz-font body-md-medium"
    >
      <div class="col-5 text-truncate">
        <LinkRoute :to="'/price-formula/edit/' + item.id" :title="item.name">
          {{ item.name }}
        </LinkRoute>
      </div>
      <div class="col text-truncate">
        <span :title="item.short_description">{{ item.short_description }}</span>
      </div>

      <div class="d-flex justify-content-end align-items-center pl-2">
        <LinkRoute
          v-if="permission.includes('WRITE')"
          :to="'/price-formula/edit/' + item.id"
          class="ant-btn ant-btn-link ant-btn-icon-only mr-1"
        >
          <a-icon type="edit" />
        </LinkRoute>
        <a-button
          v-if="permission.includes('WRITE')"
          type="link"
          icon="delete"
          @click="onClickDelete(item)"
        />
      </div>
    </div>

    <ModalDelete
      :visible="visibleModalDelete"
      :loading="confirmLoading"
      :loading-start="loadingCheckUsed"
      :varname="dataDelete.name || ''"
      :data-used="dataDelete.dataUsed || []"
      :type="$t('utils.formula')"
      @ok="onOkDelete"
      @cancel="onCloseModalDelete"
    />
  </div>
</template>

<script>
import { isUsedFormula, deleteFormula } from '@/api/price'
import LinkRoute from '@/components/ConsoleLink/LinkRoute.vue'
import ModalDelete from '@/components/Price/ModalDelete.vue'

export default {
  components: {
    LinkRoute,
    ModalDelete,
  },
  props: ['rows', 'permission'],
  data() {
    return {
      visibleModalDelete: false,
      confirmLoading: false,
      loadingCheckUsed: false,
      dataDelete: {},
    }
  },
  methods: {
    errorRequest(err) {
      this.$notification.destroy()
      this.$notification.error({
        message: this.$t('utils.failed'),
        description: err?.response?.data?.message || err.message,
      })
      setTimeout(() => {
        this.visibleModalDelete = false
      }, 500)
    },
    onCloseModalDelete() {
      this.visibleModalDelete = false
    },
    async onClickDelete(item) {
      this.loadingCheckUsed = true
      this.visibleModalDelete = true

      const { businessId, id } = item
      const business_id = businessId || this.$route.query.business_id

      let dataUsed = []

      await isUsedFormula({
        limit: 10,
        formula_id: id,
        business_id,
      })
      .then(async ({ data: { data: response } }) => {
        const data = response || []
        dataUsed = data
        if (data?.length) {
          await this.$store.dispatch('products/GETPRODUCTLISTMASTER', {
            limit: 10,
            business_id,
            product_ids: [...new Set(data.map(v => v.product_id))].join(','),
          })
            .then(resProduct => {
              if (resProduct?.data) dataUsed = resProduct.data
            })
            .catch(this.errorRequest)
            .finally(() => this.loadingCheckUsed = false)
        } else {
          this.loadingCheckUsed = false
        }
      })
      .catch(this.errorRequest)

      this.dataDelete = { ...item, dataUsed }
    },
    async onOkDelete() {
      this.confirmLoading = true
      const { businessId, id, name } = this.dataDelete
      await deleteFormula({
        id,
        data: {
          business_id: businessId || this.$route.query.business_id,
          category: 'FORMULA',
        },
      })
      .then(() => {
        this.$notification.destroy()
        this.$notification.success({
          message: this.$t('utils.deleted'),
          description: 'Formula ' + this.$t('price_setting.success_delete_msg', { name }),
        })
        this.$emit('onDeleteSuccess')
        this.dataDelete = {}
        this.visibleModalDelete = false
      })
      .catch(err => {
        this.$notification.destroy()
        this.$notification.error({
          message: this.$t('utils.failed'),
          description: err?.response?.data?.message || err.message,
        })
      })
      .finally(() => { this.confirmLoading = false })
    },
  },
}
</script>

<style lang="scss" scoped>
.list {
  border-radius: 5px !important;
  border: 1px solid #ccc !important;

  &.item {
    height: 48px !important;
    padding: 6px !important;
    margin-bottom: 6px;
  }
}
</style>
